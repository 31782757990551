import React from "react";
import Banner from "../../../components/Landers/Banner";
import Brands from "../../../components/Landers/Brands";
import Footer from "../../../components/Landers/Footer";
import GetStarted from "../../../components/Landers/GetStarted";
import Header from "../../../components/Landers/Header";
import HeresHow from "../../../components/Landers/HeresHow";
import Info from "../../../components/Landers/Info";
import styles from "../../../components/Landers/landers.module.scss";
import SectionTemplate from "../../../components/Landers/SectionTemplate";
import SEO from "../../../components/Landers/SEO";
import Testimonials from "../../../components/Landers/Testimonials";
import ThreeSteps from "../../../components/Landers/ThreeSteps";
import WhatsUnique from "../../../components/Landers/WhatsUnique";

const testimonials = [
	{
		name: "Pushpita G ",
		company: "EZOPS Technologies India Pvt. Ltd.",
		p: `I'm already in love with the UX. It's extremely user-friendly and self-explanatory. All you have to do is to
			add the candidate and it takes care of the rest. Right from running checks to real-time updates, flags, and reminders -
			SpringVerify does the job like a breeze.`,
	},
	{
		name: "Ravindra M",
		company: "Rupeezen",
		p: `I love the simplicity and clean UI of SpringVerify. As the checks are digitized, there's no need for human intervention.
			This has helped us to quicken the onboarding time for our fresh hires.`,
	},
	{
		name: "Harshavardhan N ",
		company: "Razorsharp Technologies Pvt. Ltd.",
		p: `SpringVerify is very simple to use. Initiating checks are extremely easy compared to previous product I have used so far. Their candidate and the HR portals are by far the best. Our company relies on SpringVerify everytime we run background checks on candidates. `,
	},
];

export default () => (
	<div className={styles.solutions}>
		<SEO
			title="Digital Address Verification Service| SpringVerify India"
			desc="Confirm your candidate's address by using SpringVerify's instant digital address verification service with the best turnaround time."
			url="https://in.springverify.com/screenings/address-verification/"
			imgurl="https://assets-sv-in.gumlet.io/test-pages/SV-IN+(Address+Verification).jpg"
		/>
		<div className={styles.topSection}>
			<Header />
			<Banner
				h1="Effective Address Verifications for Streamlined Hiring"
				p="Verify the candidate’s place of residence and ensure you don’t hire applicants with wrong location credentials."
				img="https://assets-sv-in.gumlet.io/LP/Address/banner.png"
			/>
		</div>
		<Brands />
		<section>
			<SectionTemplate
				img="Address/need.svg"
				h2="Importance of Address Verifications"
				p={`Address Verification helps you find whether the residential information provided by your candidate is authentic and accurate.
				It can help you to:`}
				ul={[
					"Mitigate the risk of hiring a misidentified candidate",
					"Stay compliant with rules and regulations",
					"Know your candidate's criminal history by screening their registered address",
					"Save time by making information-based hiring decisions.",
				]}
			/>
		</section>
		<HeresHow />
		<WhatsUnique
			path="Employment"
			info={[
				"Easy onboarding",
				"Safe and secure",
				"Transparent pricing",
				"Workflow automation",
				"Remote-friendly and contactless verifications",
				"Compliant with ISO 27001, PCI, and more",
				"Tailored solutions based on your requirements",
				"Powered by AI and Blockchain",
			]}
		/>
		<Testimonials testimonials={testimonials} path="Address" />
		<GetStarted
			img="Address/get-started.svg"
			h3="Verify you candidate's\naddress effortlessly"
			p="Hire the right candidate by conducting address verification with SpringVerify's digital solutions."
		/>
		<Footer />
	</div>
);
