import React from "react";
import styles from "../../components/Landers/landers.module.scss";
import styles1 from "../../components/Landers/address.module.scss";

const how = [
	"The candidate’s place of residence is visited",
	"On spot physical verification is performed",
	"Period of stay is ensured",
	"Identity proof is requested to verify the address ",
	"The candidate receives a link via SMS to kick-start the verification process",
	"Location coordinates of the residence are verified using GPS data",
	"The photo samples of their residence are captured and submitted",
	"Finally - period of stay and proof of address is verified",
];

export default () => (
	<div className={styles1.bg}>
		<section className={styles1.how}>
			<h2>
				Here’s how to get started with
				<br />
				Address Verifications
			</h2>
			<h5>Physical Address Verification</h5>
			<p>
				Our on-field representatives physically verify the address to
				confirm the
				<br />
				information provided by the candidate.
				<br />
				<br />
				Here’s how it works:
			</p>
			<div className={styles1.boxes}>
				{how.slice(0, 4).map((e, i) => (
					<div key={i}>
						<img
							data-src={`https://assets-sv-in.gumlet.io/LP/Address/How/${
								i + 1
							}.svg`}
							alt=""
						/>
						<p>{e}</p>
					</div>
				))}
			</div>
			<h5>Digital Address Verification (DAV)</h5>
			<p>
				Here, the candidate’s information is collected in real-time.
				Image uploads from mobile
				<br />
				galleries or saved pictures will not be accepted.
				<br />
				<br />
				Here’s how it works:
			</p>
			<div className={styles1.boxes}>
				{how.slice(4, 8).map((e, i) => (
					<div key={i}>
						<img
							data-src={`https://assets-sv-in.gumlet.io/LP/Address/How/${
								i + 5
							}.svg`}
							alt=""
						/>
						<p>{e}</p>
					</div>
				))}
			</div>
		</section>
	</div>
);
